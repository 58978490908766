import { Comment } from "@fscrypto/domain/comments";
import { Tag } from "@fscrypto/ui";
import { MessageCircleIcon } from "lucide-react";
import { useComments } from "../state/useComments";

export const CommentCountTag = ({
  className,
  onClick,
  resourceType,
  resourceId,
}: {
  className?: string;
  onClick?: () => void;
  resourceId: string;
  resourceType: Comment["resourceType"];
}) => {
  const { count } = useComments(resourceType, resourceId, { countOnly: true });

  return (
    <Tag
      variant="outlined"
      size="sm"
      className={className}
      prefix={<MessageCircleIcon className="h-4 w-4 text-gray-60 dark:text-gray-30" />}
      onClick={onClick}
    >
      {count ? count : ""}
    </Tag>
  );
};
